import React, { useEffect } from 'react'
import { some } from 'lodash'
import { useNavigate } from 'react-router-dom'

import { HeaderStyle } from 'src/components/layout/Header/HeaderStyle'
import LPHeader from 'src/components/layout/Header/LPHeader'
import StepsHeader from 'src/components/layout/Header/StepHeader'
import AddressEntryForm from 'src/components/common/AddressForm/AddressEntryForm'
import { useAppDispatch, useAppSelector } from 'src/store'
import { FROM_ADDRESS } from 'src/constants/app'
import { getInfo } from 'src/useCase/user'
import { USER_STATE } from 'src/enum/userStatus'
import { PATH as PATH_AUTH } from 'src/pages/auth'
import MenuNameComponent from 'src/components/common/MenuNameComponent'

const MedicalExamination: React.FC = (props: any) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const user = useAppSelector((state) => state.ui.app.authInfo)
  const isVerifyOTP =
    (user &&
      some(
        user?.state_transitions,
        (i) => i.state === USER_STATE.OTP_VERIFIED,
      )) ||
    false

  useEffect(() => {
    if (isVerifyOTP) {
      navigate(PATH_AUTH.REGISTRATION_COMPLETE)
    }
  }, [isVerifyOTP])

  useEffect(() => {
    if (!user || (user && !user.state_transitions)) {
      dispatch(
        getInfo({
          with: 'reservations,card,stateTransitions,cycleOrderPaid,latestOrderCanPay',
        }),
      )
    }
  }, [user])

  return (
    <>
      <LPHeader />
      <div className="w-full justify-center mx-auto layout-webview-step register-form">
        <StepsHeader
          step={10}
          stepActive={6}
          stepActiveName="会員登録情報入力"
          className="top-70"
        />
        <HeaderStyle>診察のご案内</HeaderStyle>
        <MenuNameComponent />
        <div className="mt-20">
          問診の回答ありがとうございます。
          <br />
          <br />
          問診内容を確認しておりますので少々お待ちください。確認の結果、医師との面談が必要となりましたら、追ってご連絡いたします。
          <br />
          <br />
          後ほど医師からご連絡を行うため会員登録をお願い致します。
        </div>
        {/* {isVerifyOTP ? (
          <ButtonCustom
            className="btn-default btn-black mt-20"
            loading={false}
            disabled={false}
            onClick={handleMyPage}
            htmlType="button"
            name="マイページ"
          />
        ) : ( */}
        <div className="mt-30">
          <HeaderStyle>会員登録情報入力</HeaderStyle>
          {/* <p>こちらに入力されたご住所にお薬を配送いたします。</p> */}
          <AddressEntryForm from={FROM_ADDRESS.MEDICAL} />
        </div>
        {/* )} */}
      </div>
    </>
  )
}

export default MedicalExamination
