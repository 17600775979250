export const USER_TREATMENT_STATE = {
  SELECTED_TREATMENT: 'selected_treatment',
  REGISTERED_INTERVIEW: 'registered_interview',
  SUBSCRIPTION_NOT_PAID: 'subscription_not_paid',
  SUBSCRIPTION_SUCCESS_PAID: 'subscription_success_paid',
  SUBSCRIPTION_SUGGESTIONS: 'subscription_suggestions',
  CHOICE_INTERVIEW_METHOD: 'choice_interview_method',
  NO_NEED_INTERVIEW: 'no_need_interview',
  VIDEO_INTERVIEW_SCHEDULED: 'video_interview_scheduled',
  CHAT_INTERVIEW_SCHEDULED: 'chat_interview_scheduled',
  SUBSCRIPTION_CONTRACT: 'subscription_contract',
  CONFIRMED_RESERVATION: 'confirmed_reservation',
  VIDEO_INTERVIEW_DATE: 'video_interview_date',
  CONFIRMING_PRESCRIPTION: 'confirming_prescription',
  NOT_PAID: 'not_paid',
  SUCCESS_PAID: 'success_paid',
  DOCTOR_PRESCRIPTION_CHECK: 'doctor_prescription_check',
  DOCTOR_PRESCRIPTION_OK: 'doctor_prescription_ok',
  CANCELED: 'canceled',
}
