import React, { Fragment, useState } from 'react'
import styled from 'styled-components'
import { Drawer } from 'antd'
import { useNavigate } from 'react-router-dom'
import { Badge } from 'antd'

import LocalStorage from 'src/utils/LocalStorage'
import { useAppDispatch, useAppSelector } from 'src/store'
import { getInfo } from 'src/useCase/user'

// Path
import { PATH } from 'src/pages/auth'
import { PATH as PATH_NOTIFICATION } from 'src/pages/notification'
import { PATH as PATH_MY_PAGE } from 'src/pages/myPage'
import { PATH as PATH_REGULAR_SHAMPOO } from 'src/pages/regular'
import { PATH as PATH_AUTH } from 'src/pages/auth'
import { PATH as PATH_LIST_TREATMENT } from 'src/pages/listTreatmentCancel'

// constants
import { WEB_FAQ_URL } from 'src/constants/env-constant'

// Img
import logoIcon from 'src/assets/images/landing/logo.svg'
import iconDelivery from 'src/assets/icons/delivery.svg'
import iconUser from 'src/assets/icons/user-white.svg'
import menuIcon from 'src/assets/icons/menu.svg'
import notificationWhite from 'src/assets/icons/notification.svg'
import closeIcon from 'src/assets/icons/close-white.svg'
import logoutIcon from 'src/assets/icons/logout.svg'
import iconTreatment from 'src/assets/icons/treatment.svg'
import iconListUserTreatmentCancel from 'src/assets/icons/list-user-treatment-cancel.svg'
import iconChat from 'src/assets/icons/chat.svg'
import iconDoctorChat from 'src/assets/icons/doctor-chat-white.svg'
import registeredPlanIcon from 'src/assets/icons/registered_plan_icon_white.svg'

export type LPMenuType = {
  name: string
  icon: string
  link: string
  target?: string
  isShow: boolean
}

const Index: React.FC = () => {
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const dispatch = useAppDispatch()
  const countUnreadNotice = useAppSelector(
    (state) => state.domain.notification.countUnreadNoticeReducer.entity,
  )

  const showDrawer = () => {
    if (LocalStorage.accessToken) {
      dispatch(
        getInfo({
          with: 'reservations,card,stateTransitions,cycleOrderPaid,latestOrderCanPay,orderPaymented',
        }),
      )
    }

    setOpen(true)
  }

  const handleLogoClick = () => {
    if (LocalStorage.accessToken) {
      dispatch(
        getInfo({
          with: 'reservations,card,stateTransitions,cycleOrderPaid,latestOrderCanPay,orderPaymented,userTreatments.reservations',
        }),
      )
      navigate(PATH_MY_PAGE.OTHER_MENU)
    } else {
      navigate(PATH_AUTH.SIGN_IN)
    }
  }

  const onClose = () => {
    setOpen(false)
  }

  const logout = () => {
    LocalStorage.clearLocalStorage()
    navigate(PATH.SIGN_IN)
  }

  const menus: LPMenuType[] = [
    {
      name: 'マイページ',
      icon: iconUser,
      link: PATH_MY_PAGE.OTHER_MENU,
      isShow: !!LocalStorage.accessToken,
    },
    {
      name: 'ご契約中のプラン',
      icon: registeredPlanIcon,
      link: PATH_MY_PAGE.PURCHASE_HISTORY,
      isShow: true,
    },
    {
      name: 'キャンセルした診療項目',
      icon: iconListUserTreatmentCancel,
      link: PATH_LIST_TREATMENT.LIST_TREATMENT_CANCEL,
      isShow: true,
    },
    {
      name: 'ドクターチャット',
      icon: iconDoctorChat,
      link: `${PATH_MY_PAGE.TREATMENT_PATH}?from=chat`,
      isShow: true,
    },
    {
      name: '新規プランの購入',
      icon: iconTreatment,
      link: PATH_AUTH.MEDICAL_SPECIALTY,
      isShow: true,
    },
    {
      name: '定期購入',
      icon: iconDelivery,
      link: PATH_REGULAR_SHAMPOO.REGULAR_SUBSCRIPTION,
      isShow: false,
    },
    {
      name: 'よくある質問',
      icon: iconChat,
      link: String(WEB_FAQ_URL),
      target: '_blank',
      isShow: true,
    },
  ]

  return (
    <Wrapper>
      <div className="menu d-flex justify-content-between">
        <img
          width="110px"
          onClick={() => handleLogoClick()}
          src={logoIcon}
          alt=""
        />
        <div className="d-flex align-items-center">
          {!!LocalStorage.accessToken && (
            <>
              <Badge className="mr-25" count={countUnreadNotice}>
                <img
                  width="23px"
                  src={notificationWhite}
                  onClick={() => navigate(PATH_NOTIFICATION.LIST_NOTIFICATION)}
                />
              </Badge>
              <img width="23px" src={menuIcon} onClick={showDrawer} />
            </>
          )}
        </div>
      </div>
      <div className="modal-menu">
        <Drawer
          className="modal-menu"
          placement="right"
          onClose={onClose}
          open={open}
          headerStyle={{ display: 'none' }}
        >
          <div>
            <div className="d-flex justify-content-end">
              <img onClick={onClose} src={closeIcon} />
            </div>
            <div className="menu">
              {menus.map((item: LPMenuType, index) => {
                return (
                  <Fragment key={index}>
                    {item.isShow && (
                      <>
                        {item.target ? (
                          <a
                            href={item.link}
                            className=""
                            key={index}
                            target={item.target}
                          >
                            <div className="menu-item flex-auto d-flex align-items-center">
                              <div>
                                <img
                                  style={{
                                    width: '30px',
                                    height: '30px',
                                  }}
                                  className="mr-5"
                                  src={item.icon}
                                  alt=""
                                />
                              </div>
                              <span className="menu-name cl-white">
                                {item.name}
                              </span>
                            </div>
                          </a>
                        ) : (
                          <div
                            onClick={() => {
                              if (
                                item.link ===
                                PATH_REGULAR_SHAMPOO.REGULAR_SUBSCRIPTION
                              ) {
                                LocalStorage.removeUserTreatmentId()
                              }
                              navigate(item.link)
                            }}
                            className=""
                            key={index}
                          >
                            <div className="menu-item flex-auto d-flex align-items-center">
                              <div>
                                <img
                                  style={{
                                    width: '30px',
                                    height: '30px',
                                  }}
                                  className="mr-5"
                                  src={item.icon}
                                  alt=""
                                />
                              </div>
                              <span className="menu-name cl-white">
                                {item.name}
                              </span>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </Fragment>
                )
              })}
            </div>
          </div>
          {!!LocalStorage.accessToken && (
            <div className="logout" onClick={logout}>
              <img
                style={{
                  width: '30px',
                  height: '30px',
                  color: '#fff',
                }}
                src={logoutIcon}
                alt=""
              />
              <span>ログアウト</span>
            </div>
          )}
        </Drawer>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .menu {
    background-color: var(--color-spring-wood);
    padding: 5px 20px 5px;
    height: 70px;

    img {
      height: auto;
    }
  }

  .modal-menu {
    display: none;
  }
`

export default Index
