import { createAsyncThunk } from '@reduxjs/toolkit'
import { UseFormReturn } from 'react-hook-form/dist/types/form'
import { get } from 'lodash'

import authRequest, {
  Credentials,
  MailResetPassType,
  ResetPasswordType,
} from 'src/api/requests/auth'
import { FormValues, ParamsType } from 'src/containers/Auth/SignIn/useLogin'
import { HTTP_CODE } from 'src/constants/app'
import LocalStorage from 'src/utils/LocalStorage'
import history from 'src/libs/history'
// import { PATH as FLOW_PATH } from 'src/pages/homeFlow'
import { PATH as AUTH_PATH } from 'src/pages/auth'
import { PATH as MY_PAGE_PATH } from 'src/pages/myPage'
import uiActions from 'src/store/ui/notification'
import appActions from '../store/ui/app'
import userRequest from '../api/requests/user'
import TagManager from 'react-gtm-module'

export interface PostLogin {
  credentials: Credentials
  meta: UseFormReturn<FormValues, any>
  params: ParamsType
}

export const login = createAsyncThunk<any, PostLogin>(
  'login',
  async ({ credentials, meta, params }, { dispatch }) => {
    try {
      const res = await authRequest.login(credentials)
      const token = get(res, 'data.access_token')

      LocalStorage.setToken(token)

      // set gg tag id
      const gtmId = get(res, 'data.currentUser.clinics[0].gtm_id', '')
      const currentGmtId = LocalStorage.getGtmId()
      LocalStorage.setGtmId(gtmId)
      if (currentGmtId !== gtmId) {
        TagManager.initialize({
          gtmId: gtmId,
        })
      }

      // const hasSubscription = userInfo && userInfo.cycleOrderPaid
      const resUserInfo = await userRequest.userInfo({
        with: 'reservations,card,stateTransitions,cycleOrderPaid,latestOrderCanPay,userTreatments,userTreatments.reservations',
      })
      dispatch(appActions.updateAuthInfo(resUserInfo.data))
      const redirectPage =
        params.redirect && atob(params.redirect)
          ? atob(params.redirect)
          : MY_PAGE_PATH.OTHER_MENU
      history.push(redirectPage)
    } catch (err: any) {
      const status = err.response.status
      if (status === HTTP_CODE.BAD_REQUEST) {
        // meta.setError('email', { type: 'validate' })
        // meta.setError('password', { type: 'validate' })
        meta.setValue('password', '')
        dispatch(
          uiActions.onNotification({
            type: 'error',
            message: 'メールアドレスまたはパスワードが正しくありません。',
          }),
        )
      }
    }
  },
)

export interface PostSendMailRessetPassword {
  mailResetPassType: MailResetPassType
  form: UseFormReturn<FormValues, any>
}

export const sendMailResetPassword = createAsyncThunk<
  any,
  PostSendMailRessetPassword
>(
  'send_mail_reset_password',
  async ({ mailResetPassType, form }, { dispatch }) => {
    try {
      const res = await authRequest.sendMailResetPassword(mailResetPassType)
      dispatch(
        uiActions.onNotification({
          type: 'success',
          message: res.data.message,
        }),
      )
    } catch (err: any) {
      const status = err.response.status
      if (status === HTTP_CODE.BAD_REQUEST) {
        dispatch(
          uiActions.onNotification({
            type: 'error',
            message: 'メールアドレスが正しくありません。',
          }),
        )
      }
    }
  },
)
export interface PostResetPassword {
  resetPassword: ResetPasswordType
  form: UseFormReturn<ResetPasswordType, any>
}

export const resetPassword = createAsyncThunk<any, PostResetPassword>(
  'reset_password',
  async ({ resetPassword, form }, { dispatch }) => {
    try {
      await authRequest.resetPassword(resetPassword)
      history.push(AUTH_PATH.COMPLETE_PAGE)
    } catch (err: any) {
      dispatch(
        uiActions.onNotification({
          type: 'error',
          message: err.response.data.message,
        }),
      )
    }
  },
)

export interface DataLinkLine {
  data: {
    code: string
    url?: string
  }
}

export const lintLine = createAsyncThunk<any, DataLinkLine>(
  'link-line',
  async ({ data }, { dispatch }) => {
    try {
      await authRequest.linkLine(data)
      LocalStorage.removePathBeforeLoginLine()
      const resUserInfo = await userRequest.userInfo({
        with: 'reservations,card,stateTransitions,cycleOrderPaid,latestOrderCanPay,orderPaymented,userTreatments,userTreatments.reservations',
      })
      dispatch(appActions.updateAuthInfo(resUserInfo.data))
      history.push(MY_PAGE_PATH.OTHER_MENU)

      dispatch(
        uiActions.onNotification({
          type: 'success',
          message: 'LINEに連携しました。',
        }),
      )
    } catch (err: any) {
      const beforeUrl = LocalStorage.getPathBeforeLoginLine()
      LocalStorage.removePathBeforeLoginLine()
      if (beforeUrl) {
        LocalStorage.removePathBeforeLoginLine()
        history.push(beforeUrl)
      } else {
        history.push(MY_PAGE_PATH.OTHER_MENU)
      }
      dispatch(
        uiActions.onNotification({
          type: 'error',
          message: err.response.data.message,
        }),
      )
    }
  },
)
