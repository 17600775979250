import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { Skeleton } from 'antd'

import LPHeader from 'src/components/layout/Header/LPHeader'
import HeaderUnderlined from 'src/components/layout/Header/HeaderUnderlined'
import { JP_DATE_FORMAT } from 'src/constants/app'
import moment from 'src/libs/moment'
import { useAppSelector, useAppDispatch } from 'src/store'
import { PATH } from 'src/pages/myPage/OtherMenu'
import { PATH as UPDATE_PASSWORD_PATH } from 'src/pages/myPage/UpdatePassword'
import { PATH as DeliveryDestinationInfo } from 'src/pages/myPage/DeliveryDestinationInfo'
import { getInfo } from 'src/useCase/user'
import { PATH as PAYMENT_INFO_PATH } from 'src/pages/myPage/PaymentInfo'
import ButtonCustom from 'src/components/form/Button'

const Container: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const user = useAppSelector((state) => state.ui.app.authInfo)
  const loading = useAppSelector((state) => state.ui.app.loading)

  useEffect(() => {
    if (!user || (user && (!user.state_transitions || !user.addresses))) {
      dispatch(
        getInfo({
          with: 'reservations,card,stateTransitions,cycleOrderPaid,latestOrderCanPay,addresses',
        }),
      )
    }
  }, [user])

  const handleNavigate = (param: string) => {
    navigate({
      pathname: DeliveryDestinationInfo,
      search: `?param=${param}`,
    })
  }

  return (
    <Wrapper>
      <LPHeader />
      {loading ? (
        <Skeleton className="p-20" />
      ) : (
        <div className="plan-detail">
          <HeaderUnderlined name="会員情報の確認・変更" />
          <div className="plan-detail__content">
            {/*<div className="section">*/}
            {/*  <div className="sec-title">配送スケジュール</div>*/}
            {/*  <div className="sec-body">*/}
            {/*    <button*/}
            {/*      onClick={() => navigate(ALL_DELIVERY_SCHEDULE)}*/}
            {/*      className="plan-detail__submit"*/}
            {/*    >*/}
            {/*      配送スケジュール確認*/}
            {/*    </button>*/}
            {/*  </div>*/}
            {/*</div>*/}
            <div className="section">
              <div className="sec-title mt-3">ご利用者情報</div>
              <div className="sec-body mt-0">
                <div className="d-flex justify-content-between content">
                  <span>氏名</span>
                  <strong>{`${user?.family_name} ${user?.given_name}`}</strong>
                </div>
                <div className="d-flex justify-content-between content">
                  <span>フリガナ</span>
                  <strong>{`${user?.family_name_kana} ${user?.given_name_kana}`}</strong>
                </div>
                <div className="d-flex justify-content-between content">
                  <span>生年月日</span>
                  <strong>
                    {user
                      ? moment(user.date_of_birth).format(JP_DATE_FORMAT)
                      : ''}
                  </strong>
                </div>
                <div className="d-flex justify-content-center">
                  <button
                    type="button"
                    className="btn-default btn-white fs-16 fw-400"
                    onClick={() => navigate(UPDATE_PASSWORD_PATH)}
                  >
                    パスワードを変更する
                  </button>
                </div>
              </div>
            </div>
            <div className="section">
              <div className="sec-title mt-3">基本情報</div>
              <div className="sec-body mt-0">
                <div className="d-flex justify-content-between content">
                  <span>郵便番号</span>
                  <strong>{user ? user.postal_number : ''}</strong>
                </div>
                <div className="d-flex justify-content-between content">
                  <span>都道府県</span>
                  <strong>{user ? user.prefecture_id : ''}</strong>
                </div>
                <div className="d-flex justify-content-between content">
                  <span>市区町村・番地</span>
                  <strong>{user ? user.city_id : ''}</strong>
                </div>
                <div className="d-flex justify-content-between content">
                  <span>番地以降</span>
                  <strong>{user ? user.address : ''}</strong>
                </div>
                <div className="d-flex justify-content-between content">
                  <span>建物名・部屋番号</span>
                  <strong>{user ? user.building_name : ''}</strong>
                </div>
                <div className="d-flex justify-content-between content">
                  <span>電話番号</span>
                  <strong>{user ? user.phone : ''}</strong>
                </div>
                <div className="d-flex justify-content-between content">
                  <span>メールアドレス</span>
                  <strong>{user ? user.email : ''}</strong>
                </div>
                {/*<button className="plan-detail__submit">*/}
                {/*  配送先を変更する*/}
                {/*</button>*/}
                <div className="d-flex justify-content-center">
                  <button
                    type="button"
                    className="btn-default btn-white fs-16 fw-400"
                    onClick={() => handleNavigate('home')}
                  >
                    基本情報をを変更する
                  </button>
                </div>
              </div>
            </div>

            <div className="section">
              <div className="sec-title mt-3">配送先</div>
              <div className="sec-body mt-0">
                <div className="d-flex justify-content-between content">
                  <span>郵便番号</span>
                  <strong>
                    {user && user.addresses && user.addresses.length > 0
                      ? user?.addresses[0].postal_number
                      : user?.postal_number || ''}
                  </strong>
                </div>
                <div className="d-flex justify-content-between content">
                  <span>都道府県</span>
                  <strong>
                    {user && user.addresses && user.addresses.length > 0
                      ? user?.addresses[0].prefecture_id
                      : user?.prefecture_id || ''}
                  </strong>
                </div>
                <div className="d-flex justify-content-between content">
                  <span>市区町村・番地</span>
                  <strong>
                    {user && user.addresses && user.addresses.length > 0
                      ? user?.addresses[0].city_id
                      : user?.city_id || ''}
                  </strong>
                </div>
                <div className="d-flex justify-content-between content">
                  <span>番地以降</span>
                  <strong>
                    {user && user.addresses && user.addresses.length > 0
                      ? user?.addresses[0].address
                      : user?.address || ''}
                  </strong>
                </div>
                <div className="d-flex justify-content-between content">
                  <span>建物名・部屋番号</span>
                  <strong>
                    {user && user.addresses && user.addresses.length > 0
                      ? user?.addresses[0].building_name
                      : user?.building_name || ''}
                  </strong>
                </div>
                <div className="d-flex justify-content-center">
                  <button
                    type="button"
                    className="btn-default btn-white fs-16 fw-400"
                    onClick={() => handleNavigate('delivery')}
                  >
                    配送先を変更する
                  </button>
                </div>
              </div>
            </div>

            <div className="section">
              <div className="sec-title mt-3">お支払方法</div>
              <div className="sec-body mt-0">
                <div className="d-flex justify-content-between content">
                  <span>お支払い方法</span>
                  <strong>クレジットカード</strong>
                </div>
                <div className="d-flex justify-content-between content">
                  <span>カード番号</span>
                  <strong>
                    {user?.card ? user?.card.masked_cc_number : ''}
                  </strong>
                </div>
                <div className="d-flex justify-content-center">
                  <button
                    type="button"
                    className="btn-default btn-white fs-16 fw-400"
                    onClick={() => navigate(PAYMENT_INFO_PATH)}
                  >
                    お支払方法を変更する
                  </button>
                </div>
                {/*<button className="plan-detail__submit">*/}
                {/*  お支払方法を変更する*/}
                {/*</button>*/}
                <ButtonCustom
                  className="btn-default btn-gurkha mt-5 btn-submit fs-16"
                  loading={false}
                  onClick={() => navigate(PATH)}
                  htmlType="button"
                  showIconRight
                  name="戻る"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .plan-detail {
    padding: 0 20px;
    &__title {
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 33px;
      letter-spacing: 0.13em;
      color: #000000;
      margin-bottom: 20px;
    }

    &__link {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      text-align: right;
      color: #1e4c82;
      margin-bottom: 15px;
      float: right;
    }

    &__content {
      margin-right: -20px;
      margin-left: -20px;

      strong {
        width: 70%;
        text-align: right;
        word-break: break-all;
      }
    }

    &__submit {
      margin-bottom: 40px;
      width: 100%;
      padding: 19px;
      color: #000000;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 27px;
      text-align: center;
      letter-spacing: 0.13em;
      background: #ffffff;
      border: 1px solid #000000;
    }

    .section {
      .sec-title {
        font-size: 15px;
        font-weight: 700;
        line-height: 18px;
        padding: 10px 20px;
        color: #000;
      }

      .sec-body {
        border-top: 1px solid var(--color-pampas);
        padding-top: 15px;
        margin: 20px;
      }

      .content {
        font-style: normal;
        font-size: 13px;
        line-height: 20px;
        margin-bottom: 15px;
        border-bottom: 1px solid #f1efe7;
        padding-bottom: 15px;
      }
    }
  }
`

export default Container
