//libs
import moment from 'moment'
import lodashGet from 'lodash/get'
import lodashIncludes from 'lodash/includes'
import lodashSome from 'lodash/some'

// constants
import { USER_TREATMENT_STATE } from 'src/enum/userTreatmentState'
import { DATE_TIME, HOUR_MIN, PLAN_TYPE, STORAGE_KEY } from 'src/constants/app'
import { USER_STATE } from 'src/enum/userStatus'

// paths
import { PATH as PATH_AUTOMATIC_PLAN } from 'src/pages/automaticPlan'
import { PATH as PATH_CHAT } from 'src/pages/chat/Chat'
import { PATH as PATH_VIDEO } from 'src/pages/video'
import { PATH as PATH_PAYMENT } from 'src/pages/payment'
import { PATH as PATH_MY_PAGE } from 'src/pages/myPage'
import { PATH as KARTE_PLAN_PATH } from 'src/pages/kartePlan'
import { PATH as PATH_REGULAR } from 'src/pages/regular'

// helpers
import LocalStorage from 'src/utils/LocalStorage'
import userTreatment from 'src/store/domain/myPage/userTreatment'

export const handleRedirectByState = (userTreatment, user, urlParams) => {
  const userTreatmentState = userTreatment.state
  const userState = user.new_state
  const userStateTransitions = user.state_transitions
  const userTreatmentTemp = userTreatment
  LocalStorage.setUserTreatmentId(userTreatment.id)
  if (userTreatment?.menu) {
    LocalStorage.setMenuName(userTreatment.menu?.name || '')
  }
  setPlanType(userTreatment.user_treatment_transitions)

  // redirect menu from chat
  if (urlParams.from === 'chat') {
    return PATH_CHAT
  }

  // WB3
  if (userTreatmentState === USER_TREATMENT_STATE.SELECTED_TREATMENT) {
    return PATH_AUTOMATIC_PLAN.QUESTIONS.replace(':id', userTreatment.menu_id)
  }

  // WB4
  if (userTreatmentState === USER_TREATMENT_STATE.REGISTERED_INTERVIEW) {
    return `${PATH_AUTOMATIC_PLAN.PRECAUTION}?menu_id=${userTreatment.menu_id}`
  }

  // WC1
  if (userTreatmentState === USER_TREATMENT_STATE.NO_NEED_INTERVIEW) {
    return PATH_AUTOMATIC_PLAN.PROPOSAL
  }

  // WD1, WH3
  if (
    lodashIncludes(
      [
        USER_TREATMENT_STATE.NOT_PAID,
        USER_TREATMENT_STATE.SUBSCRIPTION_NOT_PAID,
      ],
      userTreatmentState,
    )
  ) {
    LocalStorage.setActivePlan(userTreatment.plan_id)
    return PATH_PAYMENT.PAYMENT_INFO
  }

  const planType = LocalStorage.planType
  if (planType === PLAN_TYPE.BUY_SHAMPOO_SUBSCRIPTION) {
    return handleRedirectByFlowSubscription(
      userTreatmentState,
      userState,
      userStateTransitions,
      planType,
      userTreatmentTemp,
    )
  }

  // WB6
  if (userTreatmentState === USER_TREATMENT_STATE.SUBSCRIPTION_SUGGESTIONS) {
    return PATH_AUTOMATIC_PLAN.SUGGEST_SUBSCRIPTION
  }

  // open popup user waiting doctor confirm
  if (
    lodashIncludes(
      [
        USER_TREATMENT_STATE.DOCTOR_PRESCRIPTION_CHECK,
        USER_TREATMENT_STATE.CHOICE_INTERVIEW_METHOD,
      ],
      userTreatmentState,
    ) &&
    userState !== USER_STATE.IDENTIFICATION_NG
  ) {
    return 'popup'
  }

  // WF1
  if (userTreatmentState === USER_TREATMENT_STATE.CHAT_INTERVIEW_SCHEDULED) {
    return PATH_CHAT
  }

  // WF2
  if (userTreatmentState === USER_TREATMENT_STATE.CONFIRMING_PRESCRIPTION) {
    return KARTE_PLAN_PATH.PRESCRIPTION_PROPOSAL.replace(
      ':id',
      userTreatment.last_doctor_feedback?.id,
    )
  }

  // WG1
  if (userTreatmentState === USER_TREATMENT_STATE.VIDEO_INTERVIEW_SCHEDULED) {
    return PATH_VIDEO.VIDEO_INTERVIEW
  }

  // WG4
  if (userTreatmentState === USER_TREATMENT_STATE.CONFIRMED_RESERVATION) {
    const reservationDate = lodashGet(
      userTreatment,
      'reservations[0].reservation_date',
    )

    if (reservationDate) {
      localStorage.setItem(
        STORAGE_KEY.VIDEO_SCHEDULE,
        JSON.stringify({
          date: moment(reservationDate).format(DATE_TIME),
          time: moment(reservationDate).format(HOUR_MIN),
        }),
      )
    }

    return PATH_VIDEO.COMPLETE_TEMPORARY_RESERVE
  }

  // WM1
  if (userTreatmentState === USER_STATE.VIDEO_INTERVIEW_DATE) {
    const channelToken = lodashGet(
      userTreatment,
      'reservations[0].channel_token',
      '',
    )

    return PATH_VIDEO.VIDEO_INCOMING.replace(':token', channelToken)
  }

  if (userTreatmentState === USER_TREATMENT_STATE.DOCTOR_PRESCRIPTION_OK) {
    // user registered card
    if (userState === USER_STATE.IDENTIFICATION_OK) {
      return PATH_MY_PAGE.OTHER_MENU
    }
  }

  // WE2
  if (userState === USER_STATE.REQUESTING_SMS_VERIFICATION) {
    return PATH_AUTOMATIC_PLAN.SMS_AUTH
  }

  // WE5
  if (userState === USER_STATE.IDENTITY_CHECK) {
    return PATH_AUTOMATIC_PLAN.COMPLETE_IDENTIFICATION
  }

  if (userState === USER_STATE.IDENTIFICATION_NG) {
    return PATH_AUTOMATIC_PLAN.IDENTIFICATION
  }

  if (
    lodashIncludes(
      [
        USER_TREATMENT_STATE.SUCCESS_PAID,
        USER_TREATMENT_STATE.SUBSCRIPTION_SUCCESS_PAID,
      ],
      userTreatmentState,
    )
  ) {
    if (lodashIncludes([USER_STATE.IDENTITY_CHECK], userState)) {
      return PATH_AUTOMATIC_PLAN.IDENTIFICATION
    }

    // user registered card
    if (
      lodashIncludes(
        [USER_STATE.IDENTIFICATION_OK, USER_STATE.IDENTITY_CHECK],
        userState,
      )
    ) {
      return PATH_PAYMENT.PAYMENT_SUCCESS
    }

    // user registered info
    if (userState === USER_STATE.REQUESTING_SMS_VERIFICATION) {
      return PATH_AUTOMATIC_PLAN.SMS_AUTH
    }

    // WE4 user verify otp success
    if (userState === USER_STATE.IDENTITY_VERIFICATION_NOT_REGISTERED) {
      return PATH_AUTOMATIC_PLAN.IDENTIFICATION
    }

    // WE4: user registered info
    if (userState === USER_STATE.IDENTITY_VERIFICATION_NOT_REGISTERED) {
      return PATH_AUTOMATIC_PLAN.IDENTIFICATION
    }
    // WE1: user is not register info
    return PATH_AUTOMATIC_PLAN.ADDRESS_ENTRY
  }

  return ''
}

const setPlanType = (transitions) => {
  if (
    lodashSome(
      transitions,
      (data) => data.state === USER_STATE.CHOICE_INTERVIEW_METHOD,
    )
  ) {
    LocalStorage.setPlanType(PLAN_TYPE.PRESCRIPTION)

    return
  }

  if (
    lodashSome(
      transitions,
      (data) => data.state === USER_STATE.NO_NEED_INTERVIEW,
    )
  ) {
    LocalStorage.setPlanType(PLAN_TYPE.EXAMINATION)

    return
  }

  LocalStorage.setPlanType(PLAN_TYPE.BUY_SHAMPOO_SUBSCRIPTION)
}

//
const handleRedirectByFlowSubscription = (
  userTreatmentState,
  userState,
  userStateTransitions,
  planType,
  userTreatmentTemp,
) => {
  // WB6
  if (userTreatmentState === USER_TREATMENT_STATE.VIDEO_INTERVIEW_SCHEDULED) {
    return PATH_VIDEO.VIDEO_INTERVIEW
  }

  if (userTreatmentState === USER_TREATMENT_STATE.CONFIRMED_RESERVATION) {
    const reservationDate = lodashGet(
      userTreatment,
      'reservations[0].reservation_date',
    )

    if (reservationDate) {
      localStorage.setItem(
        STORAGE_KEY.VIDEO_SCHEDULE,
        JSON.stringify({
          date: moment(reservationDate).format(DATE_TIME),
          time: moment(reservationDate).format(HOUR_MIN),
        }),
      )
    }
    return PATH_VIDEO.COMPLETE_TEMPORARY_RESERVE
  }

  if (userTreatmentState === USER_TREATMENT_STATE.SUBSCRIPTION_SUGGESTIONS) {
    return PATH_REGULAR.REGULAR_SUBSCRIPTION
  }

  if (userTreatmentState === USER_TREATMENT_STATE.CONFIRMED_RESERVATION) {
    return PATH_VIDEO.COMPLETE_TEMPORARY_RESERVE
  }

  if (userTreatmentState === USER_TREATMENT_STATE.CONFIRMING_PRESCRIPTION) {
    return KARTE_PLAN_PATH.PRESCRIPTION_PROPOSAL.replace(
      ':id',
      userTreatmentTemp.last_doctor_feedback?.id,
    )
  }

  if (userTreatmentState === USER_TREATMENT_STATE.VIDEO_INTERVIEW_DATE) {
    const channelToken = lodashGet(
      userTreatmentTemp,
      'reservations[0].channel_token',
      '',
    )
    return PATH_VIDEO.VIDEO_INCOMING.replace(':token', channelToken)
  }

  if (
    lodashIncludes(
      [
        USER_TREATMENT_STATE.DOCTOR_PRESCRIPTION_CHECK,
        USER_TREATMENT_STATE.CHOICE_INTERVIEW_METHOD,
      ],
      userTreatmentState,
    ) &&
    userState !== USER_STATE.IDENTIFICATION_NG
  ) {
    return 'popup'
  }

  // payment success
  if (
    lodashIncludes(
      [
        USER_TREATMENT_STATE.SUCCESS_PAID,
        USER_TREATMENT_STATE.SUBSCRIPTION_SUCCESS_PAID,
      ],
      userTreatmentState,
    )
  ) {
    if (userState === USER_STATE.IDENTITY_CHECK) {
      return PATH_AUTOMATIC_PLAN.COMPLETE_IDENTIFICATION
    }

    // request opt
    if (userState === USER_STATE.REQUESTING_SMS_VERIFICATION) {
      return PATH_AUTOMATIC_PLAN.SMS_AUTH
    }

    // user registered info
    if (
      lodashSome(
        userStateTransitions,
        (data) =>
          data.state === USER_STATE.IDENTITY_VERIFICATION_NOT_REGISTERED,
      )
    ) {
      return PATH_AUTOMATIC_PLAN.IDENTIFICATION
    }
  }

  return `${PATH_AUTOMATIC_PLAN.ADDRESS_ENTRY}?plan_type=${planType}`
}
