import { UserInterface } from 'src/interfaces/user'
import {
  AddressByPostalCodeInterface,
  AddressByPostalCodeRequestInterface,
  UpdateAddressRequestInterface,
  verifyOTPRequestInterface,
} from 'src/interfaces/address'
import HTTPRequest from '../index'

const apiPathGetAddressByPostalCode = 'api/guest/postal-code'
const apiPathUpdateAddress = 'api/users/update-address'
const apiPathVerifyOTP = 'api/users/verify-otp'
const apiPathResendOTP = 'api/users/resend-otp'
const apiPathUpdateAddressDelivery = 'api/v2/users/address-delivery'

class AddressByPostalCode extends HTTPRequest {
  getAddressByPostalCode(params: AddressByPostalCodeRequestInterface) {
    return this.get<AddressByPostalCodeInterface>(
      `${apiPathGetAddressByPostalCode}`,
      {
        params,
      },
    )
  }
  updateAddress(params: UpdateAddressRequestInterface) {
    return this.post<UserInterface>(`${apiPathUpdateAddress}`, { ...params })
  }
  createAddressDelivery(
    params: Pick<
      UpdateAddressRequestInterface,
      | 'postal_number'
      | 'address'
      | 'building_name'
      | 'city_id'
      | 'prefecture_id'
    >,
  ) {
    return this.post<UserInterface>(`${apiPathUpdateAddressDelivery}`, {
      ...params,
    })
  }
  updateAddressDelivery(
    params: Pick<
      UpdateAddressRequestInterface,
      | 'postal_number'
      | 'address'
      | 'building_name'
      | 'city_id'
      | 'prefecture_id'
    >,
    address_id: number,
  ) {
    return this.put<UserInterface>(
      `${apiPathUpdateAddressDelivery}/${address_id}`,
      { ...params },
    )
  }
  verifyOTP(params: verifyOTPRequestInterface) {
    return this.post(`${apiPathVerifyOTP}`, { ...params })
  }
  resendOTP() {
    return this.post(`${apiPathResendOTP}`)
  }
}

export default new AddressByPostalCode()
