import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { PATH } from 'src/pages/video/Schedule'
import LPHeader from 'src/components/layout/Header/LPHeader'
import ButtonCustom from 'src/components/form/Button'
import MenuNameComponent from 'src/components/common/MenuNameComponent'
import LocalStorage from 'src/utils/LocalStorage'
import { getReservationUserTreatment } from 'src/useCase/userTreatment'
import { useAppDispatch, useAppSelector } from 'src/store'
import StepsHeader from 'src/components/layout/Header/StepHeader'

const Container: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [queryParams] = useSearchParams()

  const userTreatment = useAppSelector(
    (state) => state.domain.myPage.detailUserTreatment.entity,
  )

  useEffect(() => {
    const id =
      LocalStorage.getUserTreatmentId() ?? queryParams.get('userTreatmentId')
    if (id) {
      const params = {
        with: 'menu,lastReservation.doctor',
      }
      dispatch(getReservationUserTreatment({ id, params }))
    }
  }, [dispatch, queryParams])

  return (
    <Wrapper>
      <LPHeader />
      <div className="w-full justify-center mx-auto layout-webview">
        <StepsHeader
          step={10}
          stepActive={3}
          stepActiveName={'診察のご案内'}
          className="top-70"
        />
        <div className="video-interview">
          <div className="page-title mt-50 fs-20 fw-500">
            オンライン診察の案内
          </div>
          <div className="video-interview__content">
            <div className="fw-500 mb-15">
              {userTreatment?.clinic_name}担当医師の
              {userTreatment?.last_reservation?.doctor?.name}です。
            </div>
            <div className="mb-15">
              問診のご協力ありがとうございました。
              <br />
              いただいた内容をもとに診察を実施いたしますので、次のページ診察日の予約へお進みください。
            </div>
            <MenuNameComponent className="mb-15" />
          </div>
          <ButtonCustom
            className="btn-default btn-chestnut-rose mt-70"
            loading={false}
            onClick={() => navigate(PATH)}
            name="診察の予約をする"
            showIconRight
          />
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .video-interview {
    &__content {
      margin: 20px 0;
      font-size: 13px;
      font-weight: 300;
      background-color: #f1efe7;
      padding: 20px;

      span {
        color: var(--color-chestnut-rose);
        font-weight: 300;
      }
    }
  }
`

export default Container
