import { AxiosRequestConfig } from 'axios'

export const PAGINATE = {
  DEFAULT_PAGE: 1,
  PER_PAGE: 10,
}

export const HTTP_CODE = {
  OK: 200,
  CREATED: 201,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  PAYMENT_REQUIRED: 402,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  UNPROCESSABLE_ENTITY: 422,
  INTERNAL_SERVER_ERROR: 500,
}

export const DATE_TIME = 'YYYY-MM-DD'
export const DATE_FORMAT = 'DD/MM/YYYY'
export const DATE_TIME_FORMAT = 'YYYY-MM-DD'
export const DATE_TIME_HOUR = 'YYYY-MM-DD HH:mm:ss'
export const HOUR_MIN = 'HH:mm'
export const HOUR_MIN_SECOND = 'HH:mm:ss'

export const JP_DATE_FORMAT = 'YYYY年MM月DD日'
export const JP_DATE_TIME_FORMAT = 'YYYY年MM月DD日 HH:mm'

export const config: AxiosRequestConfig = {
  baseURL: `${process.env.REACT_APP_BASE_API_URL}` || 'http://localhost:3000',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
}

export const STORAGE_KEY = {
  ACCESS_TOKEN: 'access_token',
  REFRESH_TOKEN: 'refresh_token',
  AUTH_ME_INFO: 'me_info',
  VIDEO_SCHEDULE: 'video_schedule',
  PRECAUTION: 'precaution',
  ACTIVE_PLAN: 'active_plan',
  PLAN_TYPE: 'plan_type',
  AGA_ANSWER: 'aga_answer',
  AGA_TYPE: 'aga_type',
  AGA_NICK_NAME: 'aga_nick_name',
  PATH_BEFORE_LOGIN_LINE: 'path_before_login_line',
  CLINIC_UUID: 'clinic_uuid',
  VIDEO_TOKEN: 'video_token',
  USER_TREATMENT_ID: 'user_treatment_id',
  MENU_NAME: 'menu_name',
  GTM_ID: 'gtm_id',
}

export const PLAN_TYPE = {
  EXAMINATION: 'examination',
  BUY_SHAMPOO_SUBSCRIPTION: 'buy_shampoo_subscription',
  BUY_SHAMPOO_ADDITIONAL: 'buy_shampoo_additional',
  PRESCRIPTION: 'prescription',
  OTHER_MENU: 'other_menu',
}

export const FROM_ADDRESS = {
  MEDICAL: 'medical',
  PROPOSAL: 'proposal',
  REGULAR: 'regular',
}

export const FLOW_NAME = {
  AUTOMATIC_SUGGEST_PLAN_REGISTER: 'automatic-suggest-plan-register',
}
